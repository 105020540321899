<template>
  <div>
    <v-dialog persistent v-model="show" max-width="450px" max-height="800px">
      <v-card style="background-color: #f2f2f2">
        <v-card-text class="py-4">
          <v-row>
            <v-col class="px-3" cols="12">
              <p style="font-size: 17px;
    font-weight: 500;">
             The event is less than 24 hours away,
             please contact Event Support.
              </p>
            </v-col>
            <v-btn   
            class="text-capitalize"
            height="44"
            dark
            color="#2C1963"
            style="border-radius: 10px;margin-left: 173px;" @click="toggleMessageModal({ show: false })">OK</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MessageModal",
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "gameManagement/getMessageShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleMessageModal({ show: value });
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleMessageModal: "gameManagement/toggleMessageModal",
      showToast: "snackBar/showToast",
    }),
  },
};
</script>
